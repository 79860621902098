import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import SwiperCore from 'swiper';
import "../styles/swiper.css"


SwiperCore.use([Autoplay]);

export const EvenSwiper: React.FC = () => {
    const list = Array.from({ length: 19 }, (_, i) => `/images/img-swiper${19 - i}.jpg`);

    if (list.length === 0) return <></>
    return (
        <div className="swiper-section">
            <div className='swiper-button-prev'>
                <img src='/images/icon-prev.png' alt='' />
            </div>
            <Swiper
                modules={[Navigation, Pagination]}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                    disabledClass: 'disable'
                }}
                pagination={{
                    clickable: true,
                }}
                slidesPerView={1}
                spaceBetween={0}
                breakpoints={{ 800: { slidesPerView: 2, spaceBetween: 60 } }}
                autoplay
                loop={true}
            >
                {
                    list.map((item: any) => {
                        if (item === '/images/img-swiper14.jpg') return
                        return <SwiperSlide key={item}>
                            <img src={item} alt="" width={"100%"} height={"100%"} />
                        </SwiperSlide>
                    })
                }
            </Swiper>
            <div className='swiper-button-next'>
                <img src='/images/icon-next.png' alt='' />
            </div>
        </div>
    );
};
